import { Link } from "gatsby"
import React from "react"

const ErrorPage = () => {
  return (
    <div>
      <h3>This page does not exist</h3>
      <p>
        You have tried to access a page that no longer exists. Click
        <Link to="/">here</Link> to return to home page.
      </p>
    </div>
  )
}
export default ErrorPage
